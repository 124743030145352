<template>
  <article class="flightcard">
    <div class="main">
      <div class="rows">
        <div class="card_flex" v-if="flightOut">
          <div
            class="image"
            :class="{ 'is-placeholder': !flightOut.airline_image }"
            :style="`background-image: url('${flightOut.airline_image}')`"
          ></div>
          <div class="inner_flex">
            <div class="content">
              <div
                class="
                  flex flex-column
                  align-items-center
                  justify-center
                  p-l-2 p-r-2
                "
              >
                <font-awesome-icon
                  icon="plane-departure"
                  class="fas"
                  size="lg"
                />
              </div>
              <div class="date">
                <strong>
                  {{ moment(flightOut.arrival_date).format("DD MMM YYYY") }}
                </strong>
                <small>
                  {{ flightOut.airline_name }} {{ flightOut.airline_code
                  }}{{ flightOut.flight_number }}
                </small>
              </div>
              <div class="info">
                <div class="flex flex-row align-items-center justify-start">
                  <strong class="m-r-1">
                    {{ moment(flightOut.departure_date).format("HH:mm") }}
                  </strong>
                  <small>
                    {{
                      multiStop.isDepartMulti
                        ? multiStop.routes[0].total_duration
                        : flightOut.duration
                    }}
                  </small>
                  <strong class="m-l-1">
                    {{
                      multiStop.isDepartMulti
                        ? moment(multiStop.routes[0].arrival_date).format(
                            "HH:mm"
                          )
                        : moment(flightOut.arrival_date).format("HH:mm")
                    }}
                  </strong>
                </div>
                <div class="flex flex-row align-items-center justify-start">
                  <small>
                    {{ flightOut.departure_code }} –
                    {{
                      multiStop.isDepartMulti
                        ? flightIn ? flightIn.departure_code : data.routes[data.routes.length - 1].stops[1].arrival_code
                        : flightOut.arrival_code
                    }}
                  </small>
                </div>
              </div>
            </div>
            <strong
              v-if="multiStop.isDepartMulti"
              class="flex flex-row align-items-center justify-start stop_name"
              v-b-tooltip.hover.bottom="multiStop.departStopName"
              >{{
                multiStop.isDepartMulti
                  ? `${multiStop.departStop.length - 1}${
                      multiStop.departStop.length - 1 > 1 ? " stops" : " stop"
                    }`
                  : ""
              }}</strong
            >
          </div>
        </div>
        <!--  -->

        <div class="card_flex" v-if="flightIn">
          <div
            class="image"
            :class="{ 'is-placeholder': !flightIn.airline_image }"
            :style="`background-image: url('${flightIn.airline_image}')`"></div>
          <div class="inner_flex">
            <div class="content">
            <div
              class="
                flex flex-column
                align-items-center
                justify-center
                p-l-2 p-r-2
              "
            >
              <font-awesome-icon icon="plane-arrival" class="fas" size="lg" />
            </div>
            <div class="date">
              <strong>
                {{ moment(flightIn.arrival_date).format("DD MMM YYYY") }}
              </strong>
              <small
                >{{ flightIn.airline_name }} {{ flightIn.airline_code
                }}{{ flightIn.flight_number }}
              </small>
            </div>
            <div class="info">
              <div class="flex flex-row align-items-center justify-start">
                <strong class="m-r-1">
                  {{ moment(flightIn.departure_date).format("HH:mm") }}
                </strong>
                <small>
                  {{
                    multiStop.isArriveMulti
                      ? multiStop.routes[1].total_duration
                      : flightIn.duration
                  }}
                </small>
                <strong class="m-l-1">
                  {{
                    multiStop.isArriveMulti
                      ? moment(multiStop.routes[1].arrival_date).format("HH:mm")
                      : moment(flightIn.arrival_date).format("HH:mm")
                  }}
                </strong>
              </div>
              <div class="flex flex-row align-items-center justify-start">
                <small>
                  {{ flightIn.departure_code }} –
                  {{
                    multiStop.isArriveMulti
                      ? flightOut.departure_code
                      : flightIn.arrival_code
                  }}
                </small>
              </div>
            </div>
          </div>
          <strong
            v-if="multiStop.isArriveMulti"
            class="flex flex-row align-items-center justify-start stop_name"
            v-b-tooltip.hover.bottom="multiStop.arriveStopName"
            >{{
              multiStop.isArriveMulti
                ? `${multiStop.arriveStop.length - 1}${
                    multiStop.arriveStop.length - 1 > 1
                      ? " stops"
                      : " stop"
                  }`
                : ""
            }}</strong>
          </div>
        </div>
      </div>
    </div>
    <!-- Multi Stop  Start-->
    <div
      class="connected"
      v-if="multiStop.isDepartMulti || multiStop.isArriveMulti"
    >
      <div class="toggle">
        <div class="btn btn--icon-left btn--light btn--small" @click="toggle()">
          <font-awesome-icon :icon="expanded ? 'angle-up' : 'angle-down'" class="fas" />
          {{
            expanded
              ? "Hide flights"
              : "View flights"
          }}
          ({{ multiStop.departStop.length + (multiStop.arriveStop && multiStop.arriveStop.length ? multiStop.arriveStop.length : 0) - 2 }})
        </div>
      </div>
      <transition name="page">
        <div class="items" v-show="expanded">
          <h4 v-if="multiStop.departStop.length">
            Out Bound
          </h4>
          <div
            class="float-left w-100"
            v-show="multiStop.departStop.length"
            v-for="(item, i) in multiStop.departStop"
            :key="`connected_${i + 5}`"
          >
            <!-- FlightDepart Start -->
            <article
              class="flightcard"
            >
              <div class="main">
                <div class="rows">
                  <div class="card_flex">
                    <div
                      class="image"
                      :class="{ 'is-placeholder': !item.airline_image }"
                      :style="`background-image: url('${item.airline_image}')`"
                    ></div>
                    <div class="inner_flex">
                      <div class="content">
                      <div
                        class="
                          flex flex-column
                          align-items-center
                          justify-center
                          p-l-2 p-r-2
                        "
                      >
                        
                        <font-awesome-icon icon="plane-departure" class="fas" size="lg" />
                      </div>
                      <div class="date">
                        <strong>
                          {{ moment(item.arrival_date).format("DD MMM YYYY") }}
                        </strong>
                        <small>
                          {{ item.airline_name }} {{ item.airline_code
                          }}{{ item.flight_number }}
                        </small>
                      </div>
                      <div class="info">
                        <div
                          class="flex flex-row align-items-center justify-start"
                        >
                          <strong class="m-r-1">
                            {{ moment(item.departure_date).format("HH:mm") }}
                          </strong>
                          <small>
                            {{ item.duration }}
                          </small>
                          <strong class="m-l-1">
                            {{ moment(item.arrival_date).format("HH:mm") }}
                          </strong>
                        </div>
                        <div
                          class="flex flex-row align-items-center justify-start"
                        >
                          <small>
                            {{ item.departure_code }} – {{ item.arrival_code }}
                          </small>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <!-- FLightDepart End -->
            <div class="transfer">
              <span class="m-r-1" v-if="multiStop.departStop[i+1] && multiStop.departStop[i+1].is_layover"
                >Transfer here:</span
              >
              <strong v-if="multiStop.departStop[i+1] && multiStop.departStop[i+1].is_layover"
                >({{ item.arrival_code }})
                {{
                   layOverFormat(multiStop.departStop[i+1].layover_duration)
                }}</strong
              >
            </div>
          </div>
          <!-- Flight Arrive Start -->
          <h4 v-if="multiStop.arriveStop && multiStop.arriveStop.length">
            In Bound
          </h4>
          <div
            class="float-left w-100"
            v-show="multiStop.arriveStop && multiStop.arriveStop.length"
            v-for="(item, i) in multiStop.arriveStop"
            :key="`connected_${i + 10}`"
          >
            <article
              class="flightcard"
            >
              <div class="main">
                <div class="rows">

                  <div class="card_flex">
                    <div
                      class="image"
                      :class="{ 'is-placeholder': !item.airline_image }"
                      :style="`background-image: url('${item.airline_image}')`"
                    ></div>
                    <div class="inner_flex">
                      <div class="content">
                        <div
                          class="
                            flex flex-column
                            align-items-center
                            justify-center
                            p-l-2 p-r-2
                          "
                        >
                          <font-awesome-icon icon="plane-arrival" class="fas" size="lg" />
                        </div>
                        <div class="date">
                          <strong>
                            {{ moment(item.arrival_date).format("DD MMM YYYY") }}
                          </strong>
                          <small
                            >{{ item.airline_name }} {{ item.airline_code
                            }}{{ item.flight_number }}
                          </small>
                        </div>
                        <div class="info">
                          <div
                            class="flex flex-row align-items-center justify-start"
                          >
                            <strong class="m-r-1">
                              {{ moment(item.departure_date).format("HH:mm") }}
                            </strong>
                            <small>
                              {{ item.duration }}
                            </small>
                            <strong class="m-l-1">
                              {{ moment(item.arrival_date).format("HH:mm") }}
                            </strong>
                          </div>
                          <div
                            class="flex flex-row align-items-center justify-start"
                          >
                            <small>
                              {{ item.departure_code }} – {{ item.arrival_code }}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <div class="transfer">
              <span class="m-r-1" v-if="multiStop.arriveStop[i+1] && multiStop.arriveStop[i+1].is_layover"
                >Transfer here:</span
              >
              <strong v-if="multiStop.arriveStop[i+1] && multiStop.arriveStop[i+1].is_layover"
                >({{ item.arrival_code }})
                {{
                  layOverFormat(multiStop.arriveStop[i+1].layover_duration)
                }}</strong
              >
            </div>
          </div>
          <!-- Flight Arrive end -->
        </div>
      </transition>
    </div>
    <!-- Multi Stop  End-->
  </article>
</template>

<script>
import {
	VBTooltip,
} from "bootstrap-vue";
export default {
  name: "CardFlight",
  props: {
    data: {
      type: [Object, Array],
    }
  },
  directives: {
        "b-tooltip": VBTooltip
    },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    image() {
      return this.data.image || "/img/home/home-1.jpg";
    },
    flightOut() {
      if (this.data.routes?.length && this.data.routes[0].stops[0]) {
        return this.data.routes[0].stops[0];
      } else {
        return null;
      }
    },
    flightIn() {
      if (this.data.routes?.length && this.data?.routes?.[1]?.stops?.[0]) {
        return this.data.routes[1].stops[0];
      } else {
        return null;
      }
    },

    multiStop() {
      if (this.data) {
        let multiStop = this.data;
        return {
          ...multiStop,
          departStop: multiStop.routes[0].stops,
          arriveStop: multiStop?.routes?.[1]?.stops,
          isDepartMulti: multiStop.departure_stop_count > 0 ? true : false,
          isArriveMulti: multiStop.arrival_stop_count > 0 ? true : false,
          departStopName: multiStop.routes[0].stops
            .filter(
              (item, index) => index !== multiStop.routes[0].stops.length - 1
            )
            .map((item) => item.arrival_code)
            .join(","),
          arriveStopName: multiStop?.routes?.[1]?.stops
            .filter(
              (item, index) => index !== multiStop?.routes?.[1]?.stops.length - 1
            )
            .map((item) => item.arrival_code)
            .join(","),
        };
      }
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    select() {
      this.$emit("select", this.data);
      // this.setHotel(this.data);
    },
    next() {
      this.$emit("next", this.data);
    },
    view() {
      this.$emit("view", this.data);
    },
    layOverFormat(val) {
      if (val) {
        let a = val.split(":");
        let hours =
          a[0] > 0
            ? a[0] +
              (a[0] == 1
                ? "hour"
                : "hours" )
            : "";
        let minute =
          a[1] > 0
            ? a[1] +
              (a[1] == 1
                ? "minute"
                : "minutes" )
            : "";
        return `Layover Durations : ${hours} ${minute}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stop_name {
  cursor: pointer;
}
.flightcard {
  width: 100%;
  border-radius: 0.8rem;
  overflow: hidden;
  border: 1px solid #bdbcbc;
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .rows {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .card_flex {
        width: 100%;
        display: flex;
        padding: 0 15px;
        gap: 50px;
        align-items: center;
        border-bottom: 1px solid #eee;

        @media only screen and (max-width: 767px) {
          flex-flow:column;
          padding: 0 15px 15px;
          gap: 0;
        }

        .image {
          min-height: 150px;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #f9f9f9;
          width: 150px;
        }
        .inner_flex{
          width: 100%;
          display: flex;
          align-items: center;
          gap: 50px;

          @media only screen and (max-width: 767px) {
            flex-flow:column;
            gap: 20px;
          }

          .content {
            padding: 0 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 0;

            @media only screen and (max-width: 767px) {
              padding: 0;
            }
            
            @media only screen and (max-width: 480px) {
              flex-flow: column;
            }

            

            .date {
              padding: 1rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #eee;

              @media only screen and (max-width: 480px) {
                border-bottom: 1px solid #eee;
                border-right: 0;
              }

              strong {
                font-size: 1.6rem;
                width: 100%;
                text-align: center;
              }
              small {
                width: 100%;
                text-align: center;
              }
            }
            .info {
              padding: 1rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              strong {
                font-size: 1.2rem;
              }
              small {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }

    }
  }
  .connected {
    border-top: 1px solid #eee;
    .toggle {
      padding: 0.5rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .items {
      padding: 1rem;
      float: left;
      width: 100%;
      background-color: #f9f9f9;
      .flightcard {
        font-size: 0.8rem;
        color: #6e6b7b;
        background-color: #ffffff;
        &:last-child {
          margin-bottom: 0;
        }
        strong {
          font-size: 1.4rem;
        }
      }
    }
  }
  .transfer {
    width: 100%;
    float: left;
    padding: 1rem 0.5rem;
    text-align: center;
    font-size: 1.2rem;
  }
 
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid #eee;
  }
  
  
  
  .total {
    padding: 0.5rem;
    width: 100%;
    color: #000;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 1rem 0;
    small {
      margin: 0 0 0 0.5rem;
    }
  }
  .actions {
    padding: 1rem;
    flex: 12rem 0 0;
    border-left: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.fas {
  color: #3f8d85;
}
</style>
