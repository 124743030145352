<template>
  <div id="id">
    <OfferAdd v-if="!offerEdit && !isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :offer-details="offerDetails" @on-Submit="addOffer" />

    <OfferAdd v-if="offerEdit && isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :offer-details="offerDetails" @on-Submit="updateOffer" />
  </div>
</template>

<script>

import { resConditionCheck } from '@/helpers/constant'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import offerService from '@/services/offer/offer.service'
import errorResponseHandler from '@/services/errorHandler'
import moment from 'moment'
import getDateDiff from '@/helpers/dateDiff'
import OfferAdd from './OfferAdd.vue'

export default {
  name: 'OfferAddEdit',
  components: { OfferAdd },
  data() {
    return {
      isLoaded: false,
      offerDetails: {},
      offerEdit: this.$route.name !== 'add-offer',
      checkAccess: {},
    };
  },

  async mounted() {
    if (this.offerEdit) {
      await this.getOfferById(this.$route.params.id);
    }
  },

  methods: {
    // Add Offer
    async addOffer(offerAddForm) {
      const valid = await this.$refs.simpleRules.validationForm()
      const submitStatus = await this.$refs.simpleRules.isPublished
      if (!offerAddForm.image) {
        if (submitStatus === 'save') {
          this.$refs.simpleRules.loading = true
          setTimeout(() => {
            this.$refs.simpleRules.loading = false
          }, 3000)
        }
        if (submitStatus === 'publish') {
          this.$refs.simpleRules.publishLoading = true
          setTimeout(() => {
            this.$refs.simpleRules.publishLoading = false
          }, 3000)
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Offer Image is required',
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
      if(offerAddForm.checkin_date){
        let trip_checkin = moment(offerAddForm.checkin_date).format('YYYY-MM-DD')
        let today = moment(new Date()).format('YYYY-MM-DD')
        if(trip_checkin > today) {
          this.$refs.simpleRules.publishLoading = true
          setTimeout(() => {
            this.$refs.simpleRules.publishLoading = false
          }, 3000)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Offer cannot be created as checkin and checkout date of trip has passed',
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
      if (valid && offerAddForm.image) {
        if (submitStatus === 'save') {
          this.$refs.simpleRules.loading = true
        } if (submitStatus === 'publish') {
          this.$refs.simpleRules.publishLoading = true
        }
        const imageForm = Object.assign(offerAddForm)
        const clonedForm = JSON.parse(JSON.stringify(offerAddForm))
        const newForm = Object.assign(clonedForm)
        newForm.start_date = moment(offerAddForm.value2[0]).format('YYYY-MM-DD')
        newForm.end_date = moment(offerAddForm.value2[1]).format('YYYY-MM-DD')
        newForm.themes = clonedForm.themes.map(e => e.id);
        newForm.locations = clonedForm.locations.map(e => e.id)
        const formdata = new FormData()
        formdata.append('is_on_request', newForm.isOnRequest)
        formdata.append('priority_number', newForm.priority_number)
        formdata.append('start_date', newForm.start_date)
        formdata.append('end_date', newForm.end_date)
        formdata.append('offer_currency', JSON.stringify(newForm.offer_currency))
        formdata.append('trip_id', newForm.trip_id)
        formdata.append('live_price', newForm.live_price)
        formdata.append('themes', JSON.stringify(newForm.themes))
        formdata.append('locations', JSON.stringify(newForm.locations))
        formdata.append('offers', JSON.stringify(newForm.offers))
        formdata.append('image', imageForm.image)
        formdata.append('currency_code', newForm.currency_code)
        formdata.append('slug', newForm.slug)
        newForm.seo_meta.forEach(m=> {
          if(m.key=='canonical') {
            m.value = `https://${this.$store.state.auth.configData.frontendDomain}/offers/${newForm.slug}`
          }
        })
        formdata.append('seo_meta', JSON.stringify(newForm.seo_meta));

        if (submitStatus === 'publish') {
          newForm.status = 1
          formdata.append('status', newForm.status)
        } else if (submitStatus === 'save') {
          newForm.status = 2
          formdata.append('status', newForm.status)
        }

        offerService.addOffer(formdata).then(res => {
          this.$refs.simpleRules.loading = false

          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push('/offer/list')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          setTimeout(() => {
            this.$refs.simpleRules.loading = false
            this.$refs.simpleRules.publishLoading = false
          }, 3000)
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    },

    async getOfferById(id) {
      try {
        const res = await offerService.getOfferById(id)
        this.isLoaded = true;

        if (resConditionCheck(res.status) && res.data.data) {
          this.offerDetails = res.data.data
          this.offerDetails.locations = this.offerDetails.locations.map(e => ({
            ...e,
            label: e.name
          }))
          this.offerDetails.themes = this.offerDetails.themes.map(e => ({
            ...e,
            label: e.name
          }))
          this.offerDetails.savedTrips.total_adults = this.offerDetails.savedTrips.type !== 2 ? this.offerDetails.savedTrips.payload.rooms.reduce((acc, itr) => {
            let adults = acc
            adults += itr?.adults
            return adults
          }, 0) : this.offerDetails.savedTrips.payload.passengers.adults;
          this.offerDetails.savedTrips.total_children = this.offerDetails.savedTrips.type !== 2 ? this.offerDetails.savedTrips.payload.rooms.reduce((acc, itr) => {
            let children = acc
            children += itr.children?.length ? itr.children?.length : ''
            return children
          }, 0): this.offerDetails.savedTrips.payload.passengers.children.length;

          this.offerDetails.savedTrips.total_infants = this.offerDetails.savedTrips.type !== 2 ? this.offerDetails.savedTrips.payload.rooms.reduce((acc, itr) => {
            let children = acc
            children += itr.children?.length ? itr.children?.length : ''
            return children
          }, 0): this.offerDetails.savedTrips.payload.passengers.infants;
          this.offerDetails.savedTrips.nights = getDateDiff(this.offerDetails.savedTrips.payload.checkin_date, this.offerDetails.savedTrips.payload.checkout_date)
          const dateArr = []
          if (new Date(this.offerDetails.endDate) > new Date()) {
            dateArr.push(new Date(this.offerDetails.startDate))
            dateArr.push(new Date(this.offerDetails.endDate))
            this.offerDetails.dateArray = dateArr
          }
          this.offerDetails.currencySymbol = this.offerDetails.savedTrips?.type !== 2 ? this.offerDetails.savedTrips?.moduleInfo1.currency_symbol : this.offerDetails.savedTrips?.moduleInfo1.currencySymbol;
          this.offerDetails.seo_meta = this.offerDetails.seoMeta;
          this.offerDetails.onRequest = this.offerDetails.isOnRequest;
        }
      } catch (error) {
        console.error("errpr",error)
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async updateOffer(offerAddForm) {
      const valid = await this.$refs.simpleRules.validationForm()
      const submitStatus = await this.$refs.simpleRules.isPublished
      
      if (valid) {
        if (submitStatus === 'save') {
          this.$refs.simpleRules.loading = true
        } if (submitStatus === 'publish') {
          this.$refs.simpleRules.publishLoading = true
        }
        const imageForm = Object.assign(offerAddForm)
        const clonedForm = JSON.parse(JSON.stringify(offerAddForm))
        const newForm = Object.assign(clonedForm)
        newForm.start_date = moment(offerAddForm.value2[0]).format('YYYY-MM-DD')
        newForm.end_date = moment(offerAddForm.value2[1]).format('YYYY-MM-DD')
        newForm.themes = clonedForm.themes.map(e => e.id);
        newForm.locations = clonedForm.locations.map(e => e.id)
        const formdata = new FormData()

        formdata.append('is_on_request', newForm.isOnRequest)
        formdata.append('priority_number', newForm.priority_number)
        formdata.append('start_date', newForm.start_date)
        formdata.append('end_date', newForm.end_date)
        formdata.append('offer_currency', JSON.stringify(newForm.offer_currency))
        formdata.append('trip_id', newForm.trip_id)
        formdata.append('live_price', newForm.live_price)
        formdata.append('themes', JSON.stringify(newForm.themes))
        formdata.append('locations', JSON.stringify(newForm.locations))
        formdata.append('offers', JSON.stringify(newForm.offers))
        formdata.append('image', imageForm.image)
        formdata.append('currency_code', newForm.currency_code)
        formdata.append('old_trip_id', newForm.old_trip_id)
        formdata.append('slug', newForm.slug)
        
        newForm.seo_meta.forEach(m=> {
          if(m.key=='canonical') {
            m.value = `https://${this.$store.state.auth.configData.frontendDomain}/offers/${newForm.slug}`
          }
        })
        formdata.append('seo_meta', JSON.stringify(newForm.seo_meta));

        if (submitStatus === 'publish') {
          newForm.status = 1
          formdata.append('status', newForm.status)
        } else if (submitStatus === 'save') {
          newForm.status = 2
          formdata.append('status', newForm.status)
        }
        offerService.updateOffer(this.$route.params.id, formdata).then(res => {
          this.$refs.simpleRules.loading = false
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push('/offer/list')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          setTimeout(() => {
            this.$refs.simpleRules.loading = false
            this.$refs.simpleRules.publishLoading = false
          }, 3000)
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    }

  }

}
</script>
