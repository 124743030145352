import axios from 'axios'

function getAdminDropDown() {
  return axios.get('admin/list-admin/dropdown')
}

function getSavedTrips(payload) {
  return axios.get('saved-trip', { params: payload })
}

function getTripType() {
  return axios.get('module')
}

function deleteSavedTrip(id) {
  return axios.delete(`saved-trip/${id}`)
}

function getSavedTripById(id) {
  return axios.get(`saved-trip/${id}`)
}

export default {
  getAdminDropDown,
  getSavedTrips,
  getTripType,
  deleteSavedTrip,
  getSavedTripById
}
