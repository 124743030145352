import axios from 'axios'

function reorderLocation(id, data) {
  return axios.post(`location/re_order/${id}`, data)
}

function deleteLocation(id) {
  return axios.delete(`location/${id}`)
}

function addLocation(data) {
  return axios.post('location', data)
}

function getLocationById(id) {
  return axios.get(`location/${id}`)
}

function updateLocation(id, data) {
  return axios.put(`location/${id}`, data)
}

function getLocationListing() {
  return axios.get('location/admin')
}

export default {
  getLocationListing,
  reorderLocation,
  deleteLocation,
  addLocation,
  getLocationById,
  updateLocation,
}
