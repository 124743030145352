<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', offerAddForm)">
        <b-card-code>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-group
                  label="Select Languages"
                  label-for="mc-select-language"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="offerAddForm.selectedLanguages"
                    class="language"
                    :disabled="true"
                    placeholder="Select Languages"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="offerAddForm.selectedLanguages"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col v-if="$route.name === 'edit-offer'" md="6">
              <validation-provider #default="{ errors }" name="Saved Trip">
                <b-form-group
                  label="Select Saved Trip"
                  label-for="mc-select-savedtrip"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="selectedSavedTrip"
                    placeholder="Select Saved Trips"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="false"
                    :options="savedTripOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-code>

        <b-card-code>
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Location">
                <b-form-group
                  label="Select Location"
                  label-for="mc-select-location"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="offerAddForm.locations"
                    class="location"
                    multiple
                    :close-on-select="false"
                    placeholder="Select Locations"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="locationOptions"
                    label="name"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider #default="{ errors }" name="Theme">
                <b-form-group
                  label="Select Themes"
                  label-for="mc-select-theme"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="offerAddForm.themes"
                    class="theme"
                    multiple
                    :close-on-select="false"
                    placeholder="Select Themes"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="themeOptions"
                    label="name"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mt-2">
              <div class="helper" />
              <div class="drop align-center" @dragover.prevent @drop="onDrop">
                <div class="helper" />
                <label
                  v-if="!imagePreview && !offerDetails.image"
                  class="btn btn-primary display-inline"
                >
                  SELECT OR DROP AN IMAGE
                  <input type="file" name="image" @change="onChange">
                </label>
                <div
                  v-if="imagePreview"
                  class=" display-inline align-center"
                  :class="{ image: true }"
                >
                  <img
                    :src="imagePreview"
                    alt="Image"
                    class="img imagePreview_Cstm mt-2"
                  >
                  <br>
                  <br>
                  <label
                    class="btn btn-primary mb-2"
                  >
                    REPLACE
                    <input type="file" name="image" @change="onChange">
                  </label>
                </div>

                <div
                  v-if="offerDetails.image && !imagePreview && $route.params.id"
                  class=" display-inline align-center"
                  :class="{ image: true }"
                >
                  <img
                    :src="offerDetails.image"
                    alt="Image"
                    class="img imagePreview_Cstm mt-2"
                  >
                  <br>
                  <br>
                  <label
                    class="btn btn-primary mb-2"
                  >
                    REPLACE
                    <input type="file" name="image" @change="onChange">
                  </label>
                </div>
              </div>

              <b-alert class="mt-2" variant="primary" show>
                <div class="alert-body">
                  <span>Allowed JPG, JPEG, GIF or PNG. Max size of 1MB.</span>
                </div>
              </b-alert>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-group
                  label="Select Currency"
                  label-for="mc-select-currency"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="offerAddForm.selectedCurrency"
                    class="currency"
                    :disabled="true"
                    placeholder="Select Currency"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="offerAddForm.selectedCurrency"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <b-row>
                <template>
                  <div class="box">
                    <section>
                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          class="ml-1 mt-1"
                          label="Select Offer Start-End Date"
                          label-for="mc-select-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <date-picker
                            v-model="offerAddForm.value2"
                            :disabled-date="disabledRange"
                            type="date"
                            range
                            placeholder="Select date range"
                          />
                          <!-- @change="change" -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </section>
                  </div>
                </template>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Priority"
                    rules="required"
                  >
                    <b-form-group
                      class="mt-1"
                      label="Select Priority"
                      label-for="mc-select-currency"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="offerAddForm.priority_number"
                        class="priority"
                        placeholder="Select Priority"
                        :state="errors.length > 0 ? false : null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="priority"
                        :reduce="val => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <validation-provider
                #default="{ errors }"
                name="Priority"
                rules="required"
              >
              <div>
                <b-form-group
                      class="mt-1"
                      label="Book now on request"
                      label-for="mc-select-currency"
                      :state="errors.length > 0 ? false : null"
                    >
                <b-form-checkbox
                  v-model="offerAddForm.isOnRequest"
                  class="custom-control-primary"
                />
                </b-form-group>
              </div>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
          <b-row>
            <b-col class="mt-2" cols="12" md="6">
              <validation-provider #default="{ errors }" name="Live Price">
                <b-form-group
                  :label="`Live Price in ${offerAddForm.selectedCurrency[0] && offerAddForm.selectedCurrency[0].label ? offerAddForm.selectedCurrency[0].label : ''}`"
                  label-for="mc-offer-name"
                >
                  <b-form-input
                    v-model="offerAddForm.live_price"
                    readonly
                    class="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Live Price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-for="(item, index) in offerAddForm.offer_currency"
              :key="index"
              class="mt-2"
              cols="12"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                :name="`${item.currencyName} starting price`"
                rules="required|max:10|min:1|integer"
              >
                <b-form-group
                  :label="`Starting Price in ${item.currencyName}`"
                  label-for="mc-offer-name"
                >
                  <b-form-input
                    v-model="offerAddForm.offer_currency[index].starting_price"
                    class="name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      `Enter Starting Price in ${item.currencyName}`
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-code>
        <app-collapse
          v-for="(item, index) in offerAddForm.offers"
          :key="index"
          visible
          :type="collapseType"
        >
          <app-collapse-item
            :is-visible="isVisible"
            :title="`${item.languageName}`"
          >
            <b-row>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.languageName} offer name`"
                  :rules="`${item.languageName == 'Romanian' ? '' :  'required|'}max:60|min:3`"
                >
                  <b-form-group label="Offer Name" label-for="mc-offer-name">
                    <b-form-input
                      v-model="offerAddForm.offers[index].name"
                      class="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Offer Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.languageName} description`"
                  :rules="`${item.languageName == 'Romanian' ? '' :  'required'}`"
                >
                  <b-form-group label="Description" label-for="mc-description">
                    <template>
                      <quill-editor
                        v-model="offerAddForm.offers[index].description"
                        :options="snowOption"
                      />
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>

        <b-card-code class="mt-2">
          <!-- <span class="ml-1 mb-1"><h5>SEO Meta:</h5></span> -->
          <b-row>
            <b-col>
              <h4>SEO Meta:</h4>
            </b-col>
          </b-row>
          <b-row
            class="mb-1 mt-1 mr-1"
            v-for="(item, index) in offerAddForm.seo_meta"
            :id="item.id"
            :key="index"  
            ref="seoMetaRow"
          > 
          <b-col
              md="12"
              v-if="
                offerAddForm.seo_meta[index].key != 'robots' &&
                offerAddForm.seo_meta[index].key != 'type'
              "
            >
              <validation-provider
                #default="{ errors }"
                :name="upperCaseFunc(offerAddForm.seo_meta[index].key)"
                :rules="
                  offerAddForm.seo_meta[index].key == 'title'
                    ? 'required|max:60|min:1'
                    : offerAddForm.seo_meta[index].key == 'description'
                    ? 'required|max:160|min:1'
                    : offerAddForm.seo_meta[index].key == 'canonical' 
                    ? 'max:60|min:3|noWhiteSpace'
                    : 'required|min:1'
                "
              >
                <b-form-group
                  :label="upperCaseFunc(offerAddForm.seo_meta[index].key)"
                  label-for="mc-value"
                >
                  <template>
                    <div v-if="offerAddForm.seo_meta[index].key !== 'canonical'">
                      <b-form-textarea
                        v-model="offerAddForm.seo_meta[index].value"
                        id="textarea-default"
                        rows="2"
                      />
                    </div>
                    <b-input-group
                      v-if="offerAddForm.seo_meta[index].key == 'canonical'"
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      :prepend="frontendPath"
                    >
                      <b-form-input
                        v-model="offerAddForm.slug"
                        id="textarea-default"
                        class="form-control-merge"
                        @input="offerAddForm.seo_meta[index].value = `${frontendPath}${offerAddForm.slug}`"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-input-group>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
              v-if="offerAddForm.seo_meta[index].key == 'robots'"
            >
              <label for="Robots"><strong>Robots</strong></label>
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Index"
                    rules="required"
                  >
                    <b-form-group
                      label="Index"
                      label-for="mc-index"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="offerAddForm.seo_meta[index].value.index"
                        placeholder="Select Index"
                        :state="errors.length > 0 ? false : null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="RobotIndexTypes"
                        :reduce="(val) => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Follow"
                    rules="required"
                  >
                    <b-form-group
                      label="Follow"
                      label-for="mc-follow"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        v-model="offerAddForm.seo_meta[index].value.follow"
                        placeholder="Select Follow"
                        :state="errors.length > 0 ? false : null"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="RobotFollowTypes"
                        :reduce="(val) => val.value"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="offerAddForm.seo_meta[index].key == 'type'">
              <validation-provider
                #default="{ errors }"
                name="Open Graph Type"
                rules="required"
              >
                <b-form-group
                  label="Open Graph Type"
                  label-for="mc-og-type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="offerAddForm.seo_meta[index].value"
                    placeholder="Select Open Graph Type"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OGTypes"
                    :reduce="(val) => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-code>

        <b-row>
          <b-col cols="12" md="12">
            <b-card-code class="mt-4" :title="savedTrip.type === 2 ? flightTitle() : ''">
              <b-row v-if="savedTrip.type !== 2">
                <b-col cols="12" md="3">
                  <img
                    style="width:100%"
                    :src="
                      savedTrip.moduleInfo1
                        ? savedTrip.moduleInfo1.main_image
                        : ''
                    "
                    alt="Broken Image"
                    class="mw-100"
                  >
                </b-col>
                <b-col cols="12" md="5">
                  <div class="trip_detail_box">
                    <h3>
                      {{
                        savedTrip.moduleInfo1
                          ? savedTrip.moduleInfo1.hotel_name
                          : ""
                      }}
                      |
                      {{
                        savedTrip.moduleInfo1 ? savedTrip.moduleInfo1.title : ""
                      }}
                    </h3>
                    <p>
                      {{
                        savedTrip.moduleInfo1
                          ? savedTrip.moduleInfo1.full_address
                          : ""
                      }}
                    </p>
                    <p>
                      {{
                        savedTrip.moduleInfo1
                          ? savedTrip.moduleInfo1.distance
                          : ""
                      }}
                      km from center
                    </p>
                    <div v-if="savedTrip.moduleInfo1 && savedTrip.moduleInfo1.amenities">
                      <span
                        v-for="(amenity, index) of savedTrip.moduleInfo1
                          .amenities"
                        :key="index"
                        style="font-size:10px"
                        class="mr-1"
                      >
                        <b-badge variant="light-secondary">
                          {{ amenity }}
                        </b-badge>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" md="4">
                  <div class="trip_date_details">
                    <p class="d-flex flex-column" />
                    <p>
                      From :
                      {{
                        savedTrip.payload
                          ? savedTrip.payload.checkin_date
                          : "" | formatDateToString
                      }}
                    </p>
                    <p>
                      To :
                      {{
                        savedTrip.payload
                          ? savedTrip.payload.checkout_date
                          : "" | formatDateToString
                      }}
                    </p>
                    <div class="trip_duration">
                      <span>{{
                              savedTrip.total_adults > 0
                                ? `${savedTrip.total_adults} ${
                                  savedTrip.total_adults > 1 ? "adults" : "adult"
                                }`
                                : ""
                            }}
                        {{
                          savedTrip.total_children > 0
                            ? `${savedTrip.total_children} ${
                              savedTrip.total_children > 1
                                ? "children"
                                : "child"
                            }`
                            : ""
                        }}</span>
                      <span>{{
                        savedTrip.nights > 1
                          ? savedTrip.nights + " nights"
                          : savedTrip.nights + " night"
                      }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="savedTrip.type === 3" class="p-1">
                <FlightCard :data="savedTrip.moduleInfo2" />
              </b-row>
              <b-row v-if="savedTrip.type === 2">
                <FlightCard :data="savedTrip.moduleInfo1" />
              </b-row>
            </b-card-code>
          </b-col>

          <b-col md="12" class="demo-inline-spacing">
            <b-button
              :disabled="publishLoading"
              variant="success"
              type="submit"
              @click="checkPublishMethod('publish')"
            >
              Save & Publish
              <div
                v-if="publishLoading"
                class="spinner-border spinner-border-sm"
              />
            </b-button>
            <b-button
              v-if="
                this.$route.name === 'add-offer' ||
                  offerDetails.status === 2 ||
                  offerDetails.status === 3
              "
              variant="primary"
              type="submit"
              :disabled="loading"
              @click="checkPublishMethod('save')"
            >
              Save
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.view
              "
              variant="secondary"
              :to="{
                name:
                  checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                    ? 'offer-list'
                    : 'dashboard'
              }"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BAlert,
  BBadge,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox
} from "bootstrap-vue";
import { required, alpha, dateRangeCheck } from "@core/utils/validations/validations";
import checkLoginRole from "@/helpers/checkLoginRole";
import { RoleEnum, resConditionCheck, priority, RobotIndexTypes,
  RobotFollowTypes,
  OGTypes, } from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import languageService from "@/services/language/language.service";
import locationService from "@/services/banner-management/location/location.service";
import savedTripsService from "@/services/saved-trips/savedTrips.service";
import themeService from "@/services/banner-management/theme/theme.service";
import currencyService from "@/services/currency/currency.service";
import errorResponseHandler from "@/services/errorHandler";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import accessRightCheck from "@/helpers/accessRightCheck";
import offerService from "@/services/offer/offer.service";
import getDateDiff from "@/helpers/dateDiff";
import FlightCard from "../../saved-trips/saved-trips-list/FlighCard.vue";
import * as moment from "moment";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BRow,
    BCol,
    BButton,
    BAlert,
    DatePicker,
    // BButton,
    // BFormCheckbox,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    quillEditor,
    BBadge,
    FlightCard,
    BFormTextarea,
    BFormCheckbox
  },
  props: {
    offerDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    frontendPath() {
      return `https://${this.$store.state.auth.configData.frontendDomain}/offers/`;
    }
  },

  data() {
    return {
      offerAddForm: {
        isOnRequest: this.offerDetails.isOnRequest || false,
        selectedLanguages: [],
        locations: this.offerDetails?.locations || [],
        themes: this.offerDetails?.themes || [],
        image: this.offerDetails?.image || "",
        selectedCurrency: [],
        value2: this.offerDetails?.dateArray || [],
        offers: [],
        offer_currency: [],
        priority_number: this.offerDetails?.priorityNumber || "",
        start_date: "",
        end_date: "",
        trip_id: this.offerDetails?.tripId || Number(this.$route.params.id),
        old_trip_id: this.offerDetails?.tripId || Number(this.$route.params.id),
        // live_price: this.offerDetails?.livePrice || "",
        live_price: this.offerDetails?.savedTrips?.type !== 3 ? this.offerDetails?.savedTrips?.moduleInfo1?.selling_price : (Number(this.offerDetails?.savedTrips?.moduleInfo1?.selling_price) + Number(this.offerDetails?.savedTrips?.moduleInfo2?.selling_price)),
        currency_code: this.offerDetails?.currencyCode || "",
        checkin_date: '',
        seo_meta: this.offerDetails.seo_meta && this.offerDetails.seo_meta.length ? this.offerDetails.seo_meta : [
          { key: "title", value: "" },
          { key: "description", value: "" },
          { key: "canonical", value: "" },
          {
            key: "robots",
            value: {
              index: "index",
              follow: "follow",
            },
          },
          { key: "type", value: "website" },
        ],
        slug: this.offerDetails.slug ? this.offerDetails.slug : null,
      },
      required,
      alpha,
      checkLoginRole,
      collapseType: "margin",
      RoleEnum,
      loading: false,
      publishLoading: false,
      profileFile: null,
      selectedFile: null,
      staticLink: "",
      imagePreview: "",
      file: "",
      themes: [],
      locationOptions: [],
      themeOptions: [],
      savedTripOptions: [],
      selectedSavedTrip: "",
      savedTripDetails: [],
      staticObject: {
        language_id: "",
        name: "",
        description: "",
        languageName: "",
        id: ""
      },
      currencyObject: {
        starting_price: "",
        currency_id: "",
        currencyName: "",
        id: ""
      },
      savedTrip: this.offerDetails?.savedTrips || {},
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },
      priority,
      isPublished: "",
      checkAccess: {},
      isVisible: true,
      currencies: [],
      RobotIndexTypes,
      RobotFollowTypes,
      OGTypes,
    };
  },

  watch: {
    selectedSavedTrip: {
      handler(newValue) {
        this.savedTrip = this.savedTripDetails.find(e => e.id === newValue);
        this.savedTrip.nights = this.savedTrip.type !== 2 ? getDateDiff(
          this.savedTrip.payload.checkin_date,
          this.savedTrip.payload.checkout_date
        ) : 0;
        this.savedTrip.total_children = this.savedTrip.type !== 2 ? this.savedTrip.payload.rooms.reduce(
          (acc, itr) => {
            let children = acc;
            children += itr.children?.length > 0 ? itr.children?.length : "";
            return children;
          },
          0
        ) : this.savedTrip.payload.passengers.children.length;

        this.savedTrip.total_adults = this.savedTrip.type !== 2 ? this.savedTrip.payload.rooms.reduce(
          (acc, itr) => {
            let adults = acc;
            adults += itr?.adults;
            return adults;
          },
          0
        ) : this.savedTrip.payload.passengers.adults;

        this.savedTrip.total_infants = this.savedTrip.type === 2 ? this.savedTrip.payload.passengers.infants : 0;
        this.offerAddForm.trip_id = newValue;
        this.offerAddForm.live_price = this.savedTrip?.type !== 3 ? this.savedTrip?.moduleInfo1?.selling_price : Number(this.savedTrip?.moduleInfo1?.selling_price) + Number(this.savedTrip?.moduleInfo2?.selling_price);
      }
    }
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    await this.getLanguageDropdown();
    await this.getLocationDropDown();
    await this.getThemeDropDown();
    await this.getCurrencyDropDown();
    await this.getSavedTripDropDown();

    if (this.$route.name === "add-offer") {
      await this.getSavedTripById(this.$route.params.id);
    }
    if (
      (this.savedTrip.isOfferPublished || this.isOfferSaved)
      && this.$route.name === "add-offer"
    ) {
      this.$router.push("/saved-trips/list");
    }
  },

  methods: {
    disabledRange(date) {
      let year = moment(this.savedTrip.payload.checkin_date).format("YYYY")
      let day = moment(this.savedTrip.payload.checkin_date).subtract(1, 'day').format("D");
      let month = moment(this.savedTrip.payload.checkin_date).format("MM") === '01' ? '0' : moment(this.savedTrip.payload.checkin_date).subtract(1, 'month').format("M");
      return date < new Date() || date > new Date(year, month, day);
    },
    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.offerAddForm.selectedLanguages = res.data.data.map(e => ({
            label: e.name,
            value: e.id
          }));
          if (this.$route.name === "add-offer") {
            for (let i = 0; i < res.data.data.length; i += 1) {
              this.offerAddForm.offers.push({
                ...this.staticObject,
                language_id: res.data.data[i].id,
                languageName: res.data.data[i].name
              });
            }
          } else if (this.$route.name === "edit-offer") {
            for (
              let i = 0;
              i < res.data.data.length;
              i += 1
            ) {
              this.offerAddForm.offers.push({
                ...this.staticObject,
                language_id: res.data.data[i].id,
                languageName: res.data.data[i].name,
                id: this.offerDetails.offerLanguage[i] && this.offerDetails.offerLanguage[i].id ? this.offerDetails.offerLanguage[i].id : ''
              });
              this.offerAddForm.offers[i].name = this.offerDetails.offerLanguage[i] && this.offerDetails.offerLanguage[i].name ? this.offerDetails.offerLanguage[i].name : '';
              this.offerAddForm.offers[i].description = this.offerDetails.offerLanguage[i] && this.offerDetails.offerLanguage[i].description ? this.offerDetails.offerLanguage[i].description : '';
            }
          }
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async getLocationDropDown() {
      try {
        const res = await locationService.getLocationListing();
        if (resConditionCheck(res.status) && res.data.data) {
          this.locationOptions = res.data.data;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async getSavedTripDropDown() {
      try {
        const res = await offerService.getSavedTripDropDown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.savedTripDetails = res.data.data;
          res.data.data.forEach((e) => {
            if (e.type === this.savedTrip.type) {
              this.savedTripOptions.push({
                value: e.id,
                label: e.name
              })
            }
          });
          this.savedTripDetails = res.data.data;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async getCurrencyDropDown() {
      try {
        const res = await currencyService.getCurrencyDropDown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.currencies = res.data.data;
          this.offerAddForm.selectedCurrency = res.data.data.map(e => ({
            label: e.code,
            id: e.id
          }));
          if (this.$route.name === "add-offer") {
            for (let i = 0; i < res.data.data.length; i += 1) {
              this.offerAddForm.offer_currency.push({
                ...this.currencyObject,
                currency_id: res.data.data[i].id,
                currencyName: res.data.data[i].code
              });
            }
          } else if (this.$route.name === "edit-offer") {
            for (
              let i = 0;
              i < this.offerDetails.offerCurrencies.length;
              i += 1
            ) {
              this.offerAddForm.offer_currency.push({
                ...this.currencyObject,
                currency_id: this.offerDetails.offerCurrencies[i].currencyId,
                currencyName: this.offerDetails.offerCurrencies[i].currency
                  .code,
                id: this.offerDetails.offerCurrencies[i].id
              });
              this.offerAddForm.offer_currency[
                i
              ].starting_price = this.offerDetails.offerCurrencies[
                i
              ].startingPrice;
            }
            this.offerAddForm.currency_code = this.currencies.find(cr => cr.symbol == this.offerDetails.currencySymbol)?.code;
          }
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async getSavedTripById(id) {
      try {
        const res = await savedTripsService.getSavedTripById(id);
        if (resConditionCheck(res.status) && res.data.data) {
          this.savedTrip = res.data.data;
          this.savedTrip.nights = this.savedTrip.type !== 2 ? getDateDiff(
            this.savedTrip.payload.checkin_date,
            this.savedTrip.payload.checkout_date
          ) : 0;
          this.savedTrip.total_children = this.savedTrip.type !== 2 ? this.savedTrip.payload.rooms.reduce(
            (acc, itr) => {
              let children = acc;
              children += itr.children?.length > 0 ? itr.children?.length : "";
              return children;
            },
            0
          ) : this.savedTrip.payload.passengers.children.length;

          this.savedTrip.total_adults = this.savedTrip.type !== 2 ? this.savedTrip.payload.rooms.reduce(
            (acc, itr) => {
              let adults = acc;
              adults += itr?.adults;
              return adults;
            },
            0
          ) : this.savedTrip.payload.passengers.adults;

          this.savedTrip.total_adults = this.savedTrip.type === 2 ? this.savedTrip.payload.passengers.infants : 0

          // this.offerAddForm.live_price = this.savedTrip.moduleInfo1.selling_price;
          this.offerAddForm.live_price = this.savedTrip?.type !== 3 ? this.savedTrip?.moduleInfo1?.selling_price : Number(this.savedTrip?.moduleInfo1?.selling_price) + Number(this.savedTrip?.moduleInfo2?.selling_price);
          this.offerAddForm.checkin_date = this.savedTrip?.payload?.checkin_date
          let saveTripCurrencyCode = this.savedTrip?.type !== 2 ? this.savedTrip?.moduleInfo1.currency_symbol : this.savedTrip?.moduleInfo1.currencySymbol;
          this.offerAddForm.currency_code = this.currencies.find(cr => cr.symbol == saveTripCurrencyCode)?.code;
        }
      } catch (error) {
        console.error("error",error)
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async getThemeDropDown() {
      try {
        const res = await themeService.getThemeListing();
        if (resConditionCheck(res.status) && res.data.data) {
          this.themeOptions = res.data.data;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    checkPublishMethod(value) {
      this.isPublished = value;
    },

    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (
        (file.type === "image/jpeg"
          || file.type === "image/jpg"
          || file.type === "image/png"
          || file.type === "image/gif")
        && file.size <= 1048576
      ) {
        this.offerAddForm.image = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.offerAddForm.image = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    removeFile() {
      if (this.offerDetails.image) {
        this.offerDetails.image = "";
      } else {
        this.imagePreview = "";
      }
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true;
        } else {
          this.$refs.simpleRules.validate();
          valid = false;
        }
      });
      return valid;
    },

    flightTitle() {
      if(this.savedTrip.type == 2) {
        return `${this.savedTrip?.payload?.trip_type == "roundtrip" ? 'Round Trip' : 'Oneway Trip'} ${this.savedTrip?.payload?.origin_value } + ${this.savedTrip?.payload.destination_value}`
      } else {
        return `${this.savedTrip?.payload?.destination_value}`
      }
    },
    upperCaseFunc(data) {
      const title = data.replace("_", " ");
      return title.charAt(0).toUpperCase() + title.slice(1);
    },
  }
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}
.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

// .helper {
//   height: 100%;
//   display: inline-block;
//   vertical-align: middle;
//   width: 0;
// }

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.imagePreview_Cstm {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop .btn-primary {
  padding-left: 10px;
  padding-right: 10px;
}

.seoMetaRemove{
  cursor: pointer;
  border-radius: 50%;
  transition: .25s ease-in-out;
}
.seoMetaRemove:hover {
  background: rgb(187, 14, 14);
  color: #fff;
  box-shadow: 0px 0px 10px 2px #efefef;
}
</style>

<style>
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
/* .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
} */
</style>

<style lang="scss" scoped>
.input-group-text {
  background: #f2f2f2;
  padding-right: 1px;
  font-weight: bold;
  cursor: default;
}
</style>
